<template>
	
	<div>
		<headertop/>
		<headers/>
			<div class="pwidth newslist" id="newslist" v-cloak v-if="showdetail=='false'">
			    <div>
			        <div class=" arrow p-0-20 l-h-50">
			            <el-breadcrumb separator-class="el-icon-arrow-right">
			                <el-breadcrumb-item :to="{ path: '/' }">你所在的位置：</el-breadcrumb-item>
			     
			                <el-breadcrumb-item>{{newsname}}</el-breadcrumb-item>
			            </el-breadcrumb>
			        </div>
			
			        <ul class="m-b-20">
			            <h3 class="bg-f p-l-20 title  l-h-50 f-18 bold"><i class="shu m-r-5 relative top-2"></i> {{newsname}}</h3>
			            <li class="flex bg-f m-t-10" v-for="(item,index) in newslistshow" @click="articledetail(item.id)" :key="index">
			                <div class="image m-20">
								 <img v-if="item.imageurl" :src="item.imageurl" alt="">
			                    <img v-else onerror="javascript:this.src='../../assets/img/noimg.png';" src="../../assets/img/noimg.png" alt="">
			                </div>
			                <div class="detail">
			                    <h3 class="border-b f-16 flex p-r-20 cursor">
			                        <p class="">{{item.title}} </p>
			                        <p class="c-6 f-12">发布时间： {{item.createdate}}</p>
			                    </h3>
			                    <p class="p-t-20 p-r-20 contentw" v-html="removeHtmlStyle(item.conent,140)">
			                    </p>
			                </div>
			            </li>
			        </ul>
			    </div>
			</div>
			
			<div class="pwidth newsDetail " id="newdetail" v-cloak v-else>
				<div class=" arrow p-0-20 l-h-50">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item>你所在的位置：</el-breadcrumb-item>
				<!-- 		<el-breadcrumb-item> <a href="/#/index">首页</a></el-breadcrumb-item> -->
						<el-breadcrumb-item v-if="detail">{{newsname||detail.title}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
			
				<div class="m-b-20">
					<h3 class="bg-f p-l-20 title l-h-50 f-16 bold"><i class="shu  m-r-5 relative top-2" v-if="detail"></i>
						{{newsname||detail.title}}
					</h3>
			
					<div class="bg-f contents m-t-10" v-if="detail">
						<i class="wz"><img src="../../assets/img/wz.png" alt=""></i>
						<h3 class="f-24 m-b-20 tc l-h-35"> {{detail.title}}</h3>
						<p class="f-12 m-b-15 tc ">发布时间：{{detail.createdate}}</p>
						<p class="border-b c-6 p-b-10">分类：{{newsname}}</p>
						
						<div class="bg-f o-h  m-t-20 l-h-23 ">
							<p class=" m-a" v-html="detail.conentString">{{detail.conentString}}</p>
						</div>
					</div>
				</div>
			</div>
		<footers/>
	</div>
	
</template>

<script>
	import headertop from '../../components/headertop.vue';
	import headers from '../../components/header.vue';
	import footers from "../../components/footer.vue"
	export default{
		components:{footers,headertop,headers},
		data() {
			return {
				newslists: this.$store.state.app.newslist,
				newslistshow: [],
				newsname: '',
				

				detail:'',
				showdetail:this.$route.query.showdetail
			};
		},
		watch: {
			$route(){
				this.newsDetail()
				
			}
		},
		created: function created() {
			this.getTenantid(()=> {
					this.getRichtext()
			})
			var type=this.$route.query.type
			if (type == 'ZSFX') this.newsname = '知识分享';
			if (type == 'HYXW') this.newsname = '行业新闻';
			if (type == 'FLFG') this.newsname = '法律法规';
			this.newslistshow = this.newslists[type];
			if(this.$route.query.showdetail=='true') this.newsDetail()
			console.log(this.$route.query.showdetail)
		},
		methods: {
			
			// 文章详情
			articledetail: function articledetail(id) {
				// window.location.href = '/view/news/newsDetail.html?id=' + id + '&type=' + type;
				this.newsDetail(id)
				this.showdetail=true
				this.$router.push({path:'/newslist',query:{type:this.$route.query.type,showdetail:true,detailid:id}})
				
			},
			
			removeHtmlStyle(html, sub) {
				var rel = /<[^>]*>/g;
				var newHtml = '';
				if (html) {
					newHtml = html.replace(rel, '');
				}
				return newHtml.length > sub ? newHtml.substring(0, sub) + "...." : newHtml;
			},
			
			newsDetail() {
				var self = this;
				var id = this.id;
				var url = '/kbhelper/findarticle';
				var data = {id: id?id:this.$route.query.detailid}; 
				this.requset(url, data).then(function(res) {
					console.log(res)
					if(res.status=="success"){																																																																																																						
						
						self.detail = res.data;
					}else{
						self.detail ='';
					}
				
					
				});
				
			}
			
			
		}
	}
</script>

<style>
</style>
